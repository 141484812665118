/*.dzu-inputLabelWithFiles {
    display: block;
    width: 100%;
    text-align: center;
    padding: 0 0px;
    min-height: 32px;
    background-color: transparent;
    border-radius: 0px;
    font-size: 14px;
    font-weight : 600;
    margin-top  : 20px;
    margin-left : 0%;
}
.dzu-dropzone {
    border-width: 1px;
    background : transparent;
}
*/
.dzu-previewButton {
    /*background:white*/
}
.dzu-previewFileName {
    font-size : 0.75rem;
    font-weight : 200;
    color : inherit;
}
.dzu-submitButtonContainer {
    margin: 10px;
    margin-right : 30px;
    padding: 0px;
}