@font-face {
    font-family: 'Digital-7 Mono';
    font-style: normal;
    font-weight: 400;
    src: local('Digital-7 Mono'),
         local('Digital-7-Mono'),
         url("./digital-7-mono.ttf") format('truetype');
}

@font-face {
    font-family: 'Digital-7 Mono Italic';
    font-style: normal;
    font-weight: 400;
    src: local('Digital-7 Mono Italic'),
         local('Digital-7-Mono-Italic'),
         url("./digital-7-mono-italic.ttf") format('truetype');
}

@font-face {
    font-family: 'Digital-7';
    font-style: normal;
    font-weight: 400;
    src: local('Digital-7'),
         local('Digital-7'),
         url("./digital-7.ttf") format('truetype');
}