body, html {
  font      : caption;
  height    : 100%; 
}

.gjs-pn-panel{
  position: unset;
}


.gjs-mdl-dialog,
#dialogContent,
#layers-manager,
#style-manager {
  z-index : 1!important;
}


.row {
    position: initial;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
}

.gjs-row {
    display: inline-flex;
}

/*
.gjs-mdl-collector {
    z-index: 0;
}
*/

/*
.column {
  min-height: 75px;
  flex-grow: 1;
  flex-basis: 100%;
  overflow: auto;
}
*/

/*
.editor-clm {
  display: flex;
  flex-direction: column;
}
*/

.gjs-blocks-c {
  justify-content: center;
  /* height: 50px;*/
}

.gjs-block {
  width: auto;
  font-size: 5px;
  justify-content: center;
  height: auto;
  min-height: auto;
}

.gjs-mdl-dialog {
  z-index: 100000;
}

/*
.gjs-block-label {
}
*/

.gjs-cv-canvas {
  width: 100%;
  height: 100%;
  top: 0;
}

.gjs-blocks-cs {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-top: none;
}

/* Theming */
.gjs-one-bg {
  background-color: #242a3b;
}

.gjs-two-color {
  color: #9ca8bb;
}

.gjs-three-bg {
  background-color: #1e8fe1;
  color: white;
}

.gjs-four-color,
.gjs-four-color-h:hover {
  color: #1e8fe1;
}
